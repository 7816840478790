import React, { Suspense, useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const SlideshowModel = React.lazy(() => import('./views/slideshows/SlideshowModel'));
const SlideType = React.lazy(() => import('./views/slideshows/SlideType'));
const Slideshow = React.lazy(() => import('./views/slideshows/Slideshow'));

function SesaSlides() {


  return (


          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>

                <Route exact path="/slideshowModels/:id/:name" name="SlideshowModel" element={<SlideshowModel />} />
                <Route exact path="/slideTypes/:id/:name" name="SlideTypes" element={<SlideType />} />
                <Route exact path="/slideshow/:id/:name" name="Slideshow" element={<Slideshow />} />

              </Routes>
            </Suspense>
          </HashRouter>

  );
}

export default SesaSlides;
