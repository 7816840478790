import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import 'font-awesome/css/font-awesome.min.css';
import './i18n';
import SesaSlides from "./SesaSlides";

const container = document.getElementById('root')
const root = createRoot(container)

const html = document.getElementById('html')
const htmlRoot = createRoot(html)


root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

reportWebVitals()
