const initialState = {
  servicesData: [],
  error: null,
};

function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SERVICES_DATA':
      return { ...state, servicesData: action.payload, error: null };
    case 'SET_SERVICES_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export default servicesReducer;
