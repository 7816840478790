import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import {enGB, fr, es, de,  pt} from "date-fns/locale"; // import all locales we need
const locales = { enGB, fr, es, de,pt }; // used to look up the required locale


i18n

  .use(Backend)
  //detect user language
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    debug: false,
    //fallback language is english
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }

  });


export default i18n;
