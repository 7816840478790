import { createStore, combineReducers } from 'redux'
import servicesReducer from "./reducers/servicesReducer";


const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  general: changeState,       // Reducer pour l'état général
  services: servicesReducer,  // Reducer pour les données des services
});

//const store = createStore(rootReducer);
const store = createStore(changeState)
export default store
